export const ES_ASSET = `
    sellerClosingPeriod {
                  startDate
                  endDate
                }
                winnerOffer {
                  id
                  sellerBankingInfoId
                  assetsDetailed {
                    id
                  }
                }
                winnerOfferId
                id
                publishedTime
                dateModified
                pricingPlan
                currentPeriodEndDate
                currentStep
                registrationStatus
                name
                sellerClosingDocumentsAccepted
                type
                numberOfActiveOffers
                upb
                tier_1_whitelist
                tier_2_whitelist
                ownerEmail
                draftTier2Requests
                contactsDisclosed
                sellerEntityName
                sellerFirstName
                sellerLastName
                sellerPhoneNumber
                contacts {
                  emailAddress
                  firstName
                  lastName
                  companyName
                  emailAddress
                  officePhone
                } 
               documents{
                   type
                   key
                   name
                  dateModified
                  dataOwnerId
               }
                offers
                listingPrice {
                  amount
                }
                callForOffersPeriod {
                  startDate
                  endDate
                }
                  bestAndFinalPeriod {
                  startDate
                  endDate
                }
                underSelectionPeriod {
                  startDate
                  endDate
                }
                status
                assetStatus
                address
                creationDate
                images
                assetType
                favoriteForUsers
                numberOfCAs
                numberOfOffers
                numberOfAllActivities
                numberOfAllOffers
                numberOfTier2s
                numberOfViewedOnly
                numberOfImpressions
                totalUniqueViewers
                calculatorResult{
                  id
                  lowValueRange
                  percentageLowValueRangeToUpb
                  highValueRange
                  percentageHighValueRangeToUpb
                  noteRateFloorCalculated
                  chartData
                  lowSideSavings
                  lowSideTime
                  highSideSavings
                  highSideTime
                  foreclosureProcess
                  receivershipProcess
                  biddingProcess
                  seasoning
                  remainingTerm
                  ltv
                  XLLoanClass
                  marketTier
                  recommendation
                }
                noteOverview{
                  unpaidPrincipalBalance{
                    amount
                    currency
                  }
                  totalPayoff{
                    amount
                    currency
                  }
                }
                
`;
