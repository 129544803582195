import searchAssetsLocalDb from "@/services/apiCalls/asset/queries/searchAssetsLocalDb";
import { LOCAL_DATABASE } from "@/constants/localDatabase";
import { initAssetSubscriptions } from "@/utils/localDatabse/userAssets/useAssetsSubscriptions";
const DEFAULT_PARAMS = {
  offset: 0,
  orderBy: "desc",
  sortBy: "creationDate",
  size: 1000,
};
let localDatabase = null;
let store = null;
export const initUserAssets = async (dbInstance, vuexStore) => {
  localDatabase = dbInstance;
  store = vuexStore;
  await setUserAssets(localDatabase, store, true);
  await initAssetSubscriptions(store);
};
export const setUserAssets = async (dbInstance, vuexStore) => {
  localDatabase = dbInstance;
  store = vuexStore;
  const userId = store.state.user?.id;
  if (!userId) {
    return;
  }
  queryAssets(userId);

  let assets;
  if (localDatabase) {
    assets = await localDatabase.getAll(LOCAL_DATABASE.USER_ASSETS);
    store.commit("UserAssets/setUserAssets", assets);
  }
};
const queryAssets = async (userId) => {
  const { data } = await searchAssetsLocalDb({
    ...DEFAULT_PARAMS,
    owners: [userId],
  });
  const assets = data.searchAssets.assets;
  store.commit("UserAssets/setUserAssets", assets);
  localDatabase.clear(LOCAL_DATABASE.USER_ASSETS);
  for (const asset of assets) {
    await localDatabase?.put(LOCAL_DATABASE.USER_ASSETS, asset);
  }
};
