import { openDB } from "idb";

class DBInterface {
  constructor(databaseName, version, stores) {
    this.databaseName = databaseName;
    this.version = version;
    this.stores = stores;
  }

  async init() {
    await this.openDatabase();
  }
  async openDatabase() {
    try {
      this.db = await openDB(this.databaseName, this.version, {
        upgrade: (db) => {
          for (const storeName of this.stores) {
            if (!db.objectStoreNames.contains(storeName)) {
              db.createObjectStore(storeName, {
                keyPath: "id",
                autoIncrement: true,
              });
            }
          }
        },
        blocked: () => {
          if (this.db) {
            this.db.close();
          }
          console.warn("INDEXEDDB The database is blocked.");
        },
        blocking: () => {
          if (this.db) {
            this.db.close();
          }
          console.warn("INDEXEDDB The database is blocking.");
        },
        terminated() {
          console.warn("INDEXEDDB The database is terminated.");
        },
      });
    } catch (error) {
      console.error("INDEXEDDB Error opening database - ", error);
    }

    this.db.onversionchange = () => {
      try {
        this.db?.close();
      } catch (error) {
        console.error("INDEXEDDB Error closing database - ", error);
      }
    };
  }

  async get(storeName, key) {
    // await this.openDatabase();
    const tx = this.db?.transaction(storeName, "readonly");
    const store = tx.objectStore(storeName);
    const res = await store.get(key);
    // this.db.close();

    return res;
  }

  async put(storeName, value) {
    // await this.openDatabase();
    const tx = this.db?.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);

    if (value instanceof Promise) {
      value = await value;
    }

    store.put(value);
    await tx.done;
    // this.db.close();
  }

  async getAll(storeName) {
    // await this.openDatabase();
    try {
      const tx = this.db?.transaction(storeName, "readonly");
      const store = tx.objectStore(storeName);
      const res = await store.getAll();
      // this.db.close();

      return res;
    } catch (e) {
      console.warn("DB getAll - ", e);
    }
    return [];
  }

  async clear(storeName) {
    // await this.openDatabase();
    try {
      const tx = this.db?.transaction(storeName, "readwrite");
      const store = tx.objectStore(storeName);
      store.clear();
      await tx.done;
    } catch (e) {
      console.warn("DB CLEAR - ", e);
    }
  }

  async delete(storeName, key) {
    // await this.openDatabase();
    const tx = this.db?.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);
    store.delete(key);
    await tx.done;
    // this.db.close();
  }
}

export default DBInterface;
