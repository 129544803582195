import { API } from "aws-amplify";

export default {
  sendVerificationEmail(variables) {
    const query = `
      mutation sendVerificationEmail {
        sendVerificationEmail
      }  
    `;
    return API.graphql({
      query,
      variables,
    });
  },

  sendWelcomeEmail(variables) {
    const query = `
    mutation sendWelcomeEmail($email: String!)
    {
        sendWelcomeEmail(email:$email)   
    } 
   `;

    return API.graphql({
      query,
      variables,
      authMode: "AWS_IAM",
    });
  },

  verifyUser(variables) {
    const isAuth = localStorage.getItem("authenticated");

    const query = `mutation verifyUser($confirmationCode: String!, $email: String!, $password: String!) {
      verifyUser(confirmationCode: $confirmationCode, email: $email, password: $password)
    }`;

    return API.graphql({
      query,
      variables,
      authMode: isAuth ? "" : "AWS_IAM",
    });
  },

  signUp(variables) {
    const query = `mutation SignUp($email: String!, $password: String) {
      signUp(email: $email, password: $password) {
        UserConfirmed
        UserSub
        TempPassword
      }
    }`;

    return API.graphql({
      query,
      variables,
      authMode: "AWS_IAM",
    });
  },

  // CognitoSignUpResponse
};
