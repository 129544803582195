/* eslint-disable no-undef */
/* eslint-disable no-irregular-whitespace */
import Vue from "vue";
import Vuex from "vuex";
import Users from "./modules/users";
import OfferFlow from "./modules/offerFlow";
import asset from "./modules/asset";
import Settings from "./modules/settings";
import preAsset from "./modules/preAsset";
import value from "./modules/value";
import Filters from "./modules/filters";
import Offers from "./modules/offers";
import Helpers from "./modules/helpers";
import AssetFlow from "./modules/assetFlow";
import Profile from "@/services/apiCalls/profile/profile";
import multiOffer from "./modules/multiOffer";
import Helper from "@/services/apiCalls/helper";
import Seller from "./modules/seller";
import UserSettings from "./modules/userSettings";
import BDADocument from "./modules/bdaDocument";
import Deposit from "./modules/deposit";
import buyerDashboard from "./modules/buyerDashboard";
import AssetPreview from "@/store/modules/assetPreview";
import UserAssets from "./modules/userAssets";
import AuthHelpers from "./modules/auth";
import User from "./modules/user";
import Chat from "./modules/chat";
import RouteGuard from "@/store/modules/routeGuard";
import WinnerExecution from "@/store/modules/winnerExecution";
import { Storage, Auth } from "aws-amplify";
import router from "../router.js";
import DBInterface from "@/services/localDatabase/DBInterface";
import { LOCAL_DATABASE } from "@/constants/localDatabase";
import { setUserOffers } from "@/utils/localDatabse/offersDatabaseManagment";
import LocalDatabase from "@/store/modules/localDatabase";
import { setUserAssets } from "@/utils/localDatabse/userAssets/userAssetsDatabaseManagment";
import * as Sentry from "@sentry/vue";
import { deleteDB } from "idb";
Vue.use(Vuex);

const authenticated = localStorage.getItem("authenticated") === "true";

const currentUser = JSON.parse(localStorage.getItem("currentUser"));
const currentUserProfile = JSON.parse(
  localStorage.getItem("currentUserProfile")
);

export default new Vuex.Store({
  state: {
    homeGlobalSearchAssetAddress: "",
    editValuationId: null,
    isSellerEditDialogOpen: false,
    homeGlobalSearchAssetObject: null,
    drawer: false,
    drawerIsProfileLevelOpened: false,
    drawerOpenedFromProfile: false,
    localDatabase: null,
    revokeDialog: {
      isRevokeDialogOpen: false,
      title: "",
      function: null,
    },
    roleChangeSubscription: null,
    user: currentUserProfile || null,
    userAvatar: localStorage.getItem("userAvatar") || null,
    auth: {
      isAuthenticated: authenticated,
      user: currentUser || null,
    },
    spinnerShow: false,
    secondSpinnerShow: false,
    componentSpinner: false,
    assetCreationSpinner: false,
    snackbar: {
      isSnackBarShown: false,
      text: false,
      color: "primary",
    },
    ui: {
      sidebarOpened: false,
      mainSidebarOpened: false,
      buttonLoading: false,
      appOverlay: false,
    },
    logs: {
      sequenceToken: null,
    },
    sellerDashboard: {
      defaultChangeStatusDialogValue: null,
    },
  },
  getters: {
    user(state) {
      if (state.auth.user) {
        return state.user;
      }
      return null;
    },
    authenticationNeeded(state) {
      return !state.user?.roles?.includes("VerifiedUsers");
    },
    environment() {
      if (window.origin.includes("dev")) {
        return "dev";
      }
      if (window.origin.includes("test")) {
        return "test";
      }
      if (window.origin.includes("stage")) {
        return "stage";
      }
      if (window.origin.includes("localhost")) {
        return "localhost";
      } else {
        return "prod";
      }
    },
    userMainInfo(state) {
      if (state.auth.user) {
        return state.auth.user.email;
      }
      return null;
    },
    isUserProfileCompleted(state) {
      return !(
        !state.user.firstName ||
        !state.user.lastName ||
        !state.user.officePhone ||
        !state.user.address ||
        !state.user.companyName ||
        !state.user.jobTitle ||
        !state.user.companyProfile
      );
    },

    showSeller(state) {
      const roles = ["Admins", "Sellers", "Concierges"];
      if (state.user && state.user.roles) {
        if (state.user.roles.some((role) => roles.includes(role))) return true;
      }
      return false;
    },
    showBuyer(state) {
      if (state.user && state.user.roles) {
        const roles = state.user.roles;
        return !!roles.includes("ApprovedBuyers");
      } else {
        return false;
      }
    },
    showAdmin(state) {
      if (state.user && state.user && state.user.roles) {
        if (state.user.roles.includes("Admins")) return true;
      }
      return false;
    },
    pofDocument(state) {
      return state.user?.buyerProfile?.pofDocument || [];
    },
    isBuyer(state) {
      return (
        state.user &&
        state.user.roles &&
        state.user.roles.includes("ApprovedBuyers")
      );
    },
    isSeller(state) {
      return (
        state.user && state.user.roles && state.user.roles.includes("Sellers")
      );
    },
    isSellerProfileUnfilled(state) {
      if (state.user && state.user.sellerProfile) {
        const sellerProfile = state.user.sellerProfile;
        return (
          sellerProfile && //  null and undefined check
          Object.keys(sellerProfile).length === 0 &&
          Object.getPrototypeOf(sellerProfile) === Object.prototype
        );
      }
    },
    isAdmin(state) {
      return (
        state.user && state.user.roles && state.user.roles.includes("Admins")
      );
    },
  },
  actions: {
    setHJ({ state }) {
      if (window.hj) {
        const user = state.user;

        let data;
        let id;
        const funnel = window.location.pathname;
        const utm_campaign = localStorage.getItem("utm_campaign");
        if (user) {
          id = user.id;
          const buyerProfileFirstName = user.buyerProfile?.mmgpFirstName;
          const sellerProfileFirstName = user.sellerProfile?.entityName;
          const buyerProfileLastName = user.buyerProfile?.mmgpLastName;
          const sellerProfileLastName = user.sellerProfile?.entityName;
          const sellerProfilePhoneNumber = user.sellerProfile?.phoneNumber;
          const userAddress = user.address;
          const buyerProfileAddress = user.buyerProfile?.mmgpAddress;
          const sellerProfileAddress = user.buyerProfile?.entityAddress;
          const findAnyNonNulableValue = (...args) => {
            return args.find((el) => el);
          };
          data = {
            email: user.email,
            // full_name: `${user.firstName} ${user.lastName}`,
            first_name: findAnyNonNulableValue(
              user.firstName,
              buyerProfileFirstName,
              sellerProfileFirstName,
              "unregistered"
            ),

            last_name: findAnyNonNulableValue(
              user.lastName,
              buyerProfileLastName,
              sellerProfileLastName,
              "unregistered"
            ),
            company: findAnyNonNulableValue(user.companyName, "unregistered"),
            phone: findAnyNonNulableValue(
              user.officePhone,
              sellerProfilePhoneNumber,
              "unregistered"
            ),
            address: findAnyNonNulableValue(
              userAddress?.address,
              buyerProfileAddress?.address,
              sellerProfileAddress?.entityAddress,
              "unregistered"
            ),
            city: findAnyNonNulableValue(
              userAddress?.city,
              buyerProfileAddress?.city,
              sellerProfileAddress?.city,
              "unregistered"
            ),
            state: findAnyNonNulableValue(
              userAddress?.state,
              buyerProfileAddress?.state,
              sellerProfileAddress?.state,
              "unregistered"
            ),
            funnel,
            utm_campaign,
            approved_buyer: !!user.roles?.includes("Sellers"),
            approved_seller: !!user.roles?.includes("ApprovedBuyers"),
          };
        } else {
          data = {
            funnel,
            utm_campaign,
          };
          id = null;
          if (funnel === "/") {
            delete data.funnel;
          }
        }
        window.hj("identify", id, data);
      }
    },
    async fetchBankingInfo({}, bankId) {
      const result = await Profile.getBankingInfo(bankId)
        .then(({ data }) => {
          return data.getBankingInfo;
        })
        .catch((err) => {
          console.log(err);
        });
      return result;
    },
    async addBankingInfo({ commit, state }, value) {
      const { data } = await Profile.addBankingInfo(value);
      const result = data.addBankingInfo;
      const canUpdateBuyerBankingInfo =
        state.user.buyerProfile && state.user.buyerProfile.bankingInfo;
      const canUpdateSellerBankingInfo =
        state.user.sellerProfile && state.user.sellerProfile.bankingInfo;
      if (canUpdateBuyerBankingInfo) {
        commit("setUser", {
          user: {
            ...state.user,
            buyerProfile: {
              ...state.user.buyerProfile,
              bankingInfo: [result, ...state.user.buyerProfile.bankingInfo],
            },
          },
        });
      }
      if (canUpdateSellerBankingInfo) {
        commit("setUser", {
          user: {
            ...state.user,
            sellerProfile: {
              ...state.user.sellerProfile,
              bankingInfo: [result, ...state.user.sellerProfile.bankingInfo],
            },
          },
        });
      }
      return result;
    },
    async deleteBankingInfo({ commit, state }, id) {
      const { data } = await Profile.deleteBankingInfo({ id });
      const canUpdateBuyerBankingInfo =
        state.user.buyerProfile && state.user.buyerProfile.bankingInfo;
      const canUpdateSellerBankingInfo =
        state.user.sellerProfile && state.user.sellerProfile.bankingInfo;
      if (canUpdateBuyerBankingInfo) {
        commit("setUser", {
          user: {
            ...state.user,
            buyerProfile: {
              ...state.user.buyerProfile,
              bankingInfo: state.user.buyerProfile.bankingInfo.filter(
                (bank) => bank.bankingInfoId !== id
              ),
            },
          },
        });
      }
      if (canUpdateSellerBankingInfo) {
        commit("setUser", {
          user: {
            ...state.user,
            sellerProfile: {
              ...state.user.sellerProfile,
              bankingInfo: state.user.sellerProfile.bankingInfo.filter(
                (bank) => bank.bankingInfoId !== id
              ),
            },
          },
        });
      }
    },

    async editBankingInfo({ state, commit }, value) {
      const { data } = await Profile.editBankingInfo(value);
      if (!data.editBankingInfo) {
        return false;
      }
      const result = data.editBankingInfo;

      const canUpdateBuyerBankingInfo =
        state.user.buyerProfile && state.user.buyerProfile.bankingInfo;
      const canUpdateSellerBankingInfo =
        state.user.sellerProfile && state.user.sellerProfile.bankingInfo;
      if (canUpdateBuyerBankingInfo) {
        const itemToReplaceIdx = state.user.buyerProfile.bankingInfo.findIndex(
          (item) => item.bankingInfoId === value.id
        );
        state.user.buyerProfile.bankingInfo.splice(itemToReplaceIdx, 1, result);
      }
      if (canUpdateSellerBankingInfo) {
        const itemToReplaceIdx = state.user.sellerProfile.bankingInfo.findIndex(
          (item) => item.bankingInfoId === value.id
        );
        state.user.sellerProfile.bankingInfo.splice(
          itemToReplaceIdx,
          1,
          result
        );
      }
      return result;
    },
    async getMarketplaceBuildBranch() {
      const { data } = await Helper.serviceBroker();
      const { service = {} } = data;
      const { service_version = "" } = service || {};

      return service_version;
    },
    getUserAvatar({ commit, state }) {
      const avatar = state.user && state.user.avatar;
      if (avatar) {
        Storage.get(`${avatar}-thumbnail`)
          .then((res) => {
            commit("setAvatar", res);
            localStorage.setItem("userAvatar", res);
          })
          .catch((err) => console.error(err));
      }
    },
    getCurrentUserProfile({ commit, dispatch }) {
      return new Promise(async (res, rej) => {
        try {
          const currentUserProfile = await Profile.getProfile();
          if (currentUserProfile) {
            const user = currentUserProfile.data.getUserProfile;

            let ip = "";
            try {
              const response = await fetch("https://api.ipify.org?format=json");
              const data = await response.json();
              ip = data.ip;
            } catch (error) {
              console.error("Error fetching IP address:", error);
            }
            const { email, id } = user;
            const params = {
              id: id,
              username: email,
              email: email,
              ip_address: ip,
            };
            Sentry.setUser(params);

            commit("setUser", {
              user: user,
            });
          }
          res();
        } catch (err) {
          console.error("err", err);
          dispatch("logOut");
          rej();
        }
      });
    },
    async logOut({ commit, state }) {
      try {
        await Auth.signOut();
        commit("setAuthState", {
          isAuthenticated: false,
          user: null,
        });
        commit("setUser", {
          user: null,
        });
        commit("Offers/setTotalCount", 0, {
          root: true,
        });
        commit("asset/setTotalCount", 0, {
          root: true,
        });
        if (
          ![
            "/",
            "/contact",
            "/buy-commercial-real-estate-loans",
            "/asset/:id",
            "/schedule-demo",
          ].includes(this.$route)
        ) {
          router.push({ path: "/" }).catch(() => {});
        }
        localStorage.clear();
        sessionStorage.clear();
        await state.localDatabase.clear(LOCAL_DATABASE.OFFERS);
        await state.localDatabase.clear(LOCAL_DATABASE.USER_ASSETS);
        commit("buyerDashboard/SET_OFFER_ITEMS", [], { root: true });
        commit("UserAssets/setUserAssets", [], { root: true });
        await deleteDB("my-database");
        window.dataLayer?.push({
          user_id: null,
          event: "userIdSet",
        });
      } catch (e) {
        console.error(e);
      }
    },
    async logIn({ commit, dispatch, rootState }) {
      const currentUser = await Auth.currentAuthenticatedUser();
      const credentials = await Auth.currentCredentials();

      commit("setAuthState", {
        isAuthenticated: true,
        user: currentUser.attributes,
      });

      localStorage.setItem("authenticated", true);
      localStorage.setItem("identityId", credentials.identityId);
      localStorage.setItem(
        "currentUser",
        JSON.stringify(currentUser.attributes)
      );

      await dispatch("getCurrentUserProfile");

      dispatch("UserSettings/fetchUserSettings");
      dispatch("setHJ");
      dispatch("getUserAvatar");
      dispatch("asset/getUserFavoriteAssetsIds", null, {
        root: true,
      });
      await setUserOffers(rootState.localDatabase, {
        state: {
          ...rootState,
        },

        commit: commit,
      });
      await setUserAssets(rootState.localDatabase, {
        state: {
          ...rootState,
        },

        commit: commit,
      });
    },
  },
  mutations: {
    setEditValuationId(state, payload) {
      state.editValuationId = payload;
    },
    setLocalDatabase(state, payload) {
      state.localDatabase = payload;
    },
    setIsBuyerUnderReview(state, payload) {
      state.user.buyerUnderReview = payload;
    },
    setChangeStatusDefaultDialogValue(state, payload) {
      state.sellerDashboard.defaultChangeStatusDialogValue = payload;
    },
    setAuthState(state, payload) {
      state.auth = payload;
    },
    subscribeRoleChange(state, payload) {
      state.roleChangeSubscription = payload;
    },
    unsubscribeRoleChange(state) {
      state.roleChangeSubscription =
        state.roleChangeSubscription?.unsubscribe();
    },
    setIsSellerEditDialogOpen(state, payload) {
      state.isSellerEditDialogOpen = payload;
    },
    setGlobalSearchAsset(state, payload) {
      state.homeGlobalSearchAssetAddress = payload;
    },
    setGlobalSearchAssetObject(state, payload) {
      state.homeGlobalSearchAssetObject = payload;
    },

    setRevokeDialogData(state, payload) {
      state.revokeDialog = payload;
    },
    setDrawer(state, payload) {
      state.drawer = payload;
    },
    setDrawerIsProfileLevelOpened(state, payload) {
      state.drawerIsProfileLevelOpened = payload;
    },
    setIsDrawerOpenedFromProfile(state, payload) {
      state.drawerOpenedFromProfile = payload;
    },
    setBuyerProfile(state, buyerProfile) {
      state.user.buyerProfile = buyerProfile;
    },
    setUser(state, { user }) {
      if (user) {
        if (!user.address) {
          user.address = {};
        }
        if (!user.buyerProfile) {
          user.buyerProfile = {
            purchasingEntityAddress: {
              address: null,
              city: null,
              state: null,
              country: null,
              zipCode: null,
            },
            mmgpAddress: {
              address: null,
              city: null,
              state: null,
              country: null,
              zipCode: null,
            },
          };
        }
        if (!user.sellerProfile) {
          user.sellerProfile = {};
        }
        if (!user.filled) {
          user.filled = {};
        }
      }
      if (user?.buyerProfile.pofDocument) {
        user.buyerProfile.pofDocument.sort((a, b) => {
          return new Date(b.dateModified) - new Date(a.dateModified);
        });
      }
      localStorage.setItem("currentUserProfile", JSON.stringify(user));

      state.user = user;
    },
    spinner(state, show) {
      state.spinnerShow = show;
    },
    assetCreationSpinner(state, show) {
      state.assetCreationSpinner = show;
    },
    secondSpinner(state, show) {
      state.secondSpinnerShow = show;
    },

    openSnackBar(state, { text, color, isHtml = false }) {
      state.snackbar.isSnackBarShown = true;
      state.snackbar.text = text;
      state.snackbar.isHtml = isHtml;
      if (color) {
        state.snackbar.color = color;
      }
    },
    closeSnackBar(state) {
      state.snackbar.isSnackBarShown = false;
      state.snackbar.text = "";
      if (state.snackbar.color) {
        state.snackbar.color = "primary";
      }
    },
    setComponentSpinner(state, payload) {
      state.componentSpinner = payload;
    },
    changeSideBarState(state, payload) {
      state.ui.sidebarOpened = payload;
    },
    changeMainSideBarState(state, payload) {
      state.ui.mainSidebarOpened = payload;
    },
    setButtonLoad(state, payload) {
      state.ui.buttonLoading = payload;
    },
    setAvatar(state, payload) {
      state.userAvatar = payload;
    },
    setOverlayState(state, payload) {
      state.ui.appOverlay = payload;
    },
    setSequenceToken(state, token) {
      state.logs.sequenceToken = token;
    },
  },
  modules: {
    Users,
    asset,
    preAsset,
    value,
    Filters,
    Offers,
    AssetFlow,
    multiOffer,
    Helpers,
    Seller,
    Settings,
    UserSettings,
    BDADocument,
    OfferFlow,
    Deposit,
    buyerDashboard,
    AssetPreview,
    LocalDatabase,
    UserAssets,
    Chat,
    AuthHelpers,
    User,
    WinnerExecution,
    RouteGuard,
  },
});
