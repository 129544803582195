import Vue from "vue";
import VueRouter from "vue-router";
import dashboardRoutes from "./dashboard.router";
import assetsRoutes from "./assets.router";
import profileRoutes from "./profile.router";
import valueRoutes from "./value.router";
import buyerRoutes from "./buyer.router";
import adminRoutes from "./admin.rouer";
import sellerRoutes from "./seller.router";
import makeOfferFlow from "./makeOfferFlow.router";
import asset from "./asset.router";
import closingDocument from "./closingDocument.router";

import { EventBus, EVENTBUS_EVENTS } from "@/eventBus";
import { scrollBehavior } from "./scrollBehaivor.router";
import * as Sentry from "@sentry/vue";
import SentryFlow from "@/utils/SentryFlow";
import app_config from "@/app_config";
import store from "@/store";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  scrollBehavior,
  routes: [
    {
      path: "*",
      component: () => import(/* webpackChunkName: "error" */ "./pages/Error"),
      meta: {
        title: "Not Found - Xchange.Loans",
      },
    },
    {
      path: "/",
      name: "HomePage",
      component: () => import(/* webpackChunkName: "home" */ "./pages/Home"),
      meta: {
        title: "Xchange.Loans: Marketplace For Commercial Real Estate Loans",
      },
    },
    ...dashboardRoutes,
    ...assetsRoutes,
    ...profileRoutes,
    ...valueRoutes,
    ...adminRoutes,
    ...buyerRoutes,
    ...sellerRoutes,
    ...makeOfferFlow,
    ...asset,
    ...closingDocument,
    ...makeOfferFlow,
    {
      path: "/buy-commercial-real-estate-loans",
      name: "Assets",
      component: () => import("./pages/AsstetsMap.vue"),
      meta: {
        title:
          "Buy Commercial Real Estate Loans | Commercial Real Estate Loan Offers",
        hideFooter: true,
      },
    },

    {
      path: "/assets",
      redirect: "/buy-commercial-real-estate-loans",
    },
    {
      path: "/privacy-policy",
      name: "PrivacyPolicy",
      component: () => import("./pages/PrivacyPolicy.vue"),
      meta: {
        title: "Privacy policy - Xchange.Loans",
      },
    },
    {
      path: "/tos",
      name: "Terms",
      component: () => import("./pages/TermsOfServices.vue"),
      meta: {
        title: "Terms of Service - Xchange.Loans",
      },
    },

    {
      path: "/signUpConfirm",
      redirect: "/",
    },
    {
      path: "/sell",
      name: "Sell",
      component: () =>
        import(/* webpackChunkName: "id" */ "./pages/Sell/Sell.vue"),
      meta: {
        title: "Sell - Xchange.Loans",
        hideFooter: true,
      },
    },
    {
      path: "/creationComplete/:id",
      name: "Asset Creation Complete",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./pages/AssetCreationComplete/AssetCreationComplete.vue"
        ),
      meta: {
        title: "Asset Created - Xchange.Loans",
      },
    },
    {
      path: "/successPayment/:id",
      name: "Success Payment",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./pages/SuccessPayment/SuccessPayment.vue"
        ),
      meta: {
        title: "Xchange.Loans",
      },
    },
    {
      path: "/loan-sales",
      name: "Self service",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./pages/PaymentTypes/LenderDirect.vue"
        ),
      meta: {
        title:
          "Lender Direct | List and sell your loan on our secure self-serve marketplace",
      },
    },
    {
      path: "/loan-sale-advisory",
      name: "Full service",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./pages/PaymentTypes/XLExclusive.vue"
        ),
      meta: {
        title:
          "XL Exclusive | Let us sell your loan for you with no seller fees",

        metaTags: [
          {
            name: "description",
            content:
              " Let us sell your distressed commercial real estate loans with ease and confidence with our XL Exclusive concierge service. Our team of experts will handle the entire process ensuring maximum returns for your assets.",
          },
        ],
      },
    },

    {
      path: "/favorites",
      component: () => import("./pages/Buyer/BuyerFavorites"),
      meta: {
        requiresAuth: true,
        title: "Favorites - Xchange.Loans",
      },
    },

    {
      path: "/faq",
      name: "Faq",
      component: () =>
        import(/* webpackChunkName: "home" */ "./pages/FAQ/FAQ.vue"),
      meta: {
        title:
          "Frequently Asked Questions on Xchange Loans | Your loan sale marketplace",
      },
    },
    {
      path: "/styleguide",
      name: "Styleguide",
      component: () =>
        import(/* webpackChunkName: "home" */ "./pages/Styleguide.vue"),
      meta: {
        title: "Styleguide - Xchange.Loans",
      },
    },

    {
      path: "/about-xchange-loans",
      name: "AboutUs",
      component: () =>
        import(/* webpackChunkName: "home" */ "./pages/AboutUs/AboutUs.vue"),
      meta: {
        title: "About Xchange Loans | Your loan sale marketplace",
      },
    },
    {
      path: "/aboutUs",
      redirect: "/about-xchange-loans",
    },
    {
      path: "/choosePlan",
      redirect: "/loan-sales",
    },
    {
      path: "/choosePlan/lender",
      redirect: "/loan-sales",
    },
    {
      path: "/value/assetCreation",
      redirect: "/free-loan-valuation",
    },
    {
      path: "/trepp",
      redirect: "/buy-commercial-real-estate-loans",
    },
  ],
});

router.beforeResolve(async (to, from, next) => {
  const loggedIn = localStorage.getItem("authenticated");
  if (to.path === "/login") {
    EventBus.$emit(EVENTBUS_EVENTS.OPEN_LOGIN_DIALOG, to);
    return next({
      path: "/",
    });
  }
  if (to.path === "/register") {
    EventBus.$emit(EVENTBUS_EVENTS.OPEN_REGISTER_DIALOG, to);
    return next({
      path: "/",
    });
  }

  if (!loggedIn) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      EventBus.$emit(EVENTBUS_EVENTS.OPEN_LOGIN_DIALOG, to);
      return next({
        path: "/",
      });
    }
  }

  if (
    to.matched.some(
      (record) =>
        typeof record.meta.requiresAuth === "boolean" &&
        !record.meta.requiresAuth
    )
  ) {
    return next();
  }
  return next();
});

Sentry.init({
  Vue,
  dsn: app_config.SENTRY_DSN,
  environment: app_config.SENTRY_ENVIRONMENT,
  tracingOptions: {
    trackComponents: true,
  },
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [window.location.origin],
      blockAllMedia: false,
      maskAllText: false,
    }),
    // networkDetailAllowUrls: [window.location.origin],
  ],
  tracesSampleRate: 1.0,
  profilesSampleRate: 1.0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
  enabled: ["prod", "stage"].includes(store?.getters?.environment),
});
const sentryReplay = Sentry.getReplay();
let replayStatus = false;
router.beforeEach((to, from, next) => {
  const { status } = SentryFlow(to, from, sentryReplay, replayStatus);

  replayStatus = status;

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
  next();
});

router.onError((error) => {
  console.error(error);

  const isChunkLoadError = /ChunkLoadError:.*failed./i.test(error.message);
  const isLoadingChunkError = /Loading.*chunk.*failed./i.test(error.message);

  if (isChunkLoadError || isLoadingChunkError) {
    window.location.reload();
  }
});

export default router;
