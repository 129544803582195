import Offer from "@/services/apiCalls/offer/offer";
import ErrorParser from "@/utils/ErrorParser";
import { LOCAL_DATABASE } from "@/constants/localDatabase";
import {
  onSubscribeAssetUpdateByBot,
  onSubscribeToEditOffer,
  onSubscribeToNoticeESOffersUpdate,
  onSubscribeToOfferDecline,
  onSubscribeToOffersUpdatesByBot,
  onSubscribeToOfferWin,
  subscribeAssetChange,
} from "@/utils/offer/offerSubscriptions";
import { convertOfferToESOffer } from "@/utils/mappers/offer/OfferToESOffer";
import { of } from "twilio/lib/base/values";
const OFFERS_DEFAULT_PARAMS = {
  address: null,
  type: ["SingleBid", "MultipleBid"],
  offset: 0,
  size: 1000,
};
let localDatabase = null;
let store = null;

export const initUserOffers = async (db, vuex) => {
  await setUserOffers(db, vuex);
  await subscribeToAssetOfferUpdates();
};
export const setUserOffers = async (db, vuex) => {
  localDatabase = db;
  store = vuex;

  try {
    const userRoles = store.state.user.roles;
    if (!userRoles || !userRoles.includes("ApprovedBuyers")) {
      return;
    }
    if (localDatabase) {
      const offers = await localDatabase.getAll(LOCAL_DATABASE.OFFERS);
      vuex.commit("buyerDashboard/SET_OFFER_ITEMS", offers);
      queryOffers();
    }
  } catch (err) {}
};
const queryOffers = async () => {
  const { data } = await Offer.searchOffersByUser_LOCAL_DB(
    OFFERS_DEFAULT_PARAMS
  ).catch((err) => {
    ErrorParser(err);
  });
  localDatabase.clear(LOCAL_DATABASE.OFFERS);
  const offers = data.searchOffersByUser.offers;
  for (const offer of offers) {
    await localDatabase?.put(LOCAL_DATABASE.OFFERS, offer);
  }
  store.commit("buyerDashboard/SET_OFFER_ITEMS", offers);
};

const subscribeToAssetOfferUpdates = () => {
  try {
    subscribeAssetChange(onUpdateAsset);
    onSubscribeToOfferWin(onNextOfferSub);
    onSubscribeToOfferDecline(onNextOfferSub);
    onSubscribeToEditOffer(onNextOfferSub);
    onSubscribeToOffersUpdatesByBot((data) => {
      data.value.data.subscribeToOffersUpdatesByBot.forEach(
        async (offerFromSub) => {
          console.log("111111 offerFromSub - ", offerFromSub);
          console.log("OFFER UPDATES BY BOT offerFromSub - ", offerFromSub);
          const { offer, offerIdx } = getOfferObjIndexById(offerFromSub.id);
          await updateOfferInList(offerIdx, offer, offerFromSub);

          const convertedOffer = convertOfferToESOffer(
            offer || {},
            offerFromSub
          );
          console.log("convertedOffer - ", convertedOffer);

          winningProcess(convertedOffer);
        }
      );
    });
    onSubscribeAssetUpdateByBot((data) => {
      const assets = data.value.data.subscribeToAssetsUpdatesByBot;
      assets.forEach(async (asset) => {
        await onUpdateAsset(asset);
      });
    });
    onSubscribeToNoticeESOffersUpdate(onESOfferUpdate);
  } catch (err) {
    console.log("sbscribe to offer updates err", err);
  }
};
const onESOfferUpdate = async (data, subName) => {
  try {
    const subOffers = data.value.data[subName];
    Promise.all(
      subOffers.map(async (subOffer) => {
        const { offer, offerIdx } = getOfferObjIndexById(subOffer.id);
        await updateESOfferInList(offerIdx, offer, subOffer);
      })
    );
  } catch (err) {
    console.log("onESOfferUpdate err", err);
  }
};

const onUpdateAsset = async (asset) => {
  const newOffer = store.dispatch("buyerDashboard/updateAsset", asset);
  if (newOffer) {
    await localDatabase?.put(LOCAL_DATABASE.OFFERS, newOffer);
  }
};
const getOfferObjIndexById = (offerId) => {
  const offerItems = store.state.buyerDashboard.offerItems;
  const offer = offerItems.find((offer) => offer.id === offerId);
  const offerIdx = offerItems.findIndex((offer) => offer.id === offerId);
  return {
    offer,
    offerIdx,
  };
};

const onNextOfferSub = async (data, subName) => {
  const subOffer = data.value.data[subName];

  const { offer, offerIdx } = getOfferObjIndexById(subOffer.id);
  await updateOfferInList(offerIdx, offer, subOffer);
};
const updateOfferInList = async (offerToUpdateIdx, oldOffer, newOffer) => {
  if (oldOffer) {
    // in this list we have ESOffer type, from sub we getting Offer type, we need convert Offer to ESOffer to prevent errors
    const mappedESOffer = convertOfferToESOffer(oldOffer, newOffer);
    const offerToPush = { ...oldOffer, ...mappedESOffer };
    store.commit("buyerDashboard/UPDATE_OFFER_IN_LIST", {
      offerToUpdateIdx,
      newOffer: offerToPush,
    });
    await localDatabase?.put(LOCAL_DATABASE.OFFERS, offerToPush);
  }
};
const updateESOfferInList = async (offerToUpdateIdx, oldOffer, newOffer) => {
  if (oldOffer) {
    store.commit("buyerDashboard/UPDATE_OFFER_IN_LIST", {
      offerToUpdateIdx,
      newOffer: { ...oldOffer, ...newOffer },
    });
    await localDatabase?.put(LOCAL_DATABASE.OFFERS, newOffer);
  }
};

const updateOffer = (offer) => {
  const newOffer = { ...offer };
  const assetsDetailed = newOffer.assetsDetailed.reduce((acc, asset) => {
    acc[asset.id] = asset;
    return acc;
  }, {});
  newOffer.assets?.forEach((asset) => {
    const detailedInfo = assetsDetailed[asset.id];
    if (detailedInfo) {
      asset.owners = detailedInfo.owners;
      asset.assetStatus = detailedInfo.assetStatus;
      asset.images = detailedInfo.images;
      asset.address = detailedInfo.address;
    }
  });
  return newOffer;
};

const winningProcess = (convertedOffer) => {
  const updatedAssetsOffer = updateOffer(convertedOffer);
  console.log("winningProcess - updatedAssetsOffer - ", updatedAssetsOffer);
  if (
    updatedAssetsOffer.status === "Successful" &&
    updatedAssetsOffer.owners &&
    updatedAssetsOffer.owners[0] === store.state.user.id &&
    updatedAssetsOffer.buyerClosingPeriod &&
    new Date(updatedAssetsOffer.buyerClosingPeriod.endDate).getTime() -
      new Date().getTime() >
      0 &&
    !updatedAssetsOffer.buyerClosingDocumentsAccepted &&
    !updatedAssetsOffer.sellerClosingPeriod &&
    !updatedAssetsOffer.sellerClosingDocumentsAccepted
  ) {
    console.log("winningProcess - updatedAssetsOffer - 1");
    store.commit("WinnerExecution/setOffers", [updatedAssetsOffer]);
    store.commit("WinnerExecution/addBuyerOffers", [updatedAssetsOffer]);
  } else if (
    updatedAssetsOffer.status === "Successful" &&
    updatedAssetsOffer.assets[0].owners &&
    updatedAssetsOffer.assets[0].owners[0] === store.state.user.id &&
    updatedAssetsOffer.buyerClosingPeriod &&
    updatedAssetsOffer.buyerClosingDocumentsAccepted &&
    updatedAssetsOffer.sellerClosingPeriod &&
    new Date(updatedAssetsOffer.sellerClosingPeriod.endDate).getTime() -
      new Date().getTime() >
      0 &&
    !updatedAssetsOffer.sellerClosingDocumentsAccepted
  ) {
    console.log("winningProcess - updatedAssetsOffer - 2");
    store.commit("WinnerExecution/setOffers", [updatedAssetsOffer]);
    store.commit("WinnerExecution/addSellerOffers", [updatedAssetsOffer]);
  } else if (updatedAssetsOffer.status === "Canceled") {
    console.log("winningProcess - updatedAssetsOffer - 3", updatedAssetsOffer);
    const removeParams = {
      offer: updatedAssetsOffer,
    };
    if (
      updatedAssetsOffer.owners &&
      updatedAssetsOffer.owners[0] === store.state.user.id
    ) {
      console.log("winningProcess - updatedAssetsOffer - 31");
      store.commit("WinnerExecution/removeOfferFromWinnerLists", {
        ...removeParams,
        role: "buyer",
      });
    } else if (
      updatedAssetsOffer.assets[0].owners &&
      updatedAssetsOffer.assets[0].owners[0] === store.state.user.id
    ) {
      console.log("winningProcess - updatedAssetsOffer - 32");
      store.commit("WinnerExecution/removeOfferFromWinnerLists", {
        ...removeParams,
        role: "seller",
      });
    }
  }
};
