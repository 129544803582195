import { editOfferGuard } from "@/routerGuards/guardEditOffer";

export default [
  {
    path: "/buy-commercial-real-estate-loans/assets/:id?",
    name: "MakeOfferAssetsById",
    component: () => import("./components/MakeOfferFlow/Assets.vue"),
    beforeEnter: editOfferGuard,
    meta: {
      hideFooter: true,
      makeOfferFlow: true,
    },
  },
  {
    path: "/buy-commercial-real-estate-loans/deposit-agreement/:id?",
    name: "MakeOfferSigna",
    component: () =>
      import("./components/MakeOfferFlow/BidDepositAgreement.vue"),
    beforeEnter: editOfferGuard,
    meta: {
      hideFooter: true,
      makeOfferFlow: true,
    },
  },
  {
    path: "/buy-commercial-real-estate-loans/offer-info/:id?",
    name: "MakeOfferForm",
    component: () => import("./components/MakeOfferFlow/OfferInfo.vue"),
    beforeEnter: editOfferGuard,
    meta: {
      hideFooter: true,
      makeOfferFlow: true,
    },
  },
  {
    path: "/buy-commercial-real-estate-loans/review-submit/:id",
    name: "MakeOfferReview",
    component: () => import("./components/MakeOfferFlow/ReviewSubmit.vue"),
    beforeEnter: editOfferGuard,
    meta: {
      hideFooter: true,
      makeOfferFlow: true,
    },
  },
];
