import guardAdmin from "./router";

export default [
  {
    path: "/profile",
    redirect: "/profile/settings",
    component: () => import("./components/layout/ProfileLayout.vue"),
    meta: {
      hideFooter: true,
    },
    children: [
      {
        path: "seller",
        component: () =>
          import(
            /* webpackChunkName: "seller" */ "./pages/Profile/ProfileSeller"
          ),
        meta: {
          requiresAuth: true,
          title: "Seller Profile - Xchange.Loans",
          hideFooter: true,
        },
      },
      {
        path: "buyer",
        component: () =>
          import(
            /* webpackChunkName: "buyer" */ "./pages/Profile/ProfileBuyer"
          ),
        meta: {
          requiresAuth: true,
          title: "Buyer Profile - Xchange.Loans",
          hideFooter: true,
        },
      },
      {
        path: "edit",
        name: "ProfileEdit",
        component: () =>
          import(
            /* webpackChunkName: "ProfileEdit" */ "./pages/Profile/ProfileEdit"
          ),
        meta: {
          requiresAuth: true,
          title: "Edit Profile - Xchange.Loans",
          hideFooter: true,
        },
      },
      {
        path: "billing/:id?",
        name: "ProfileEditBilling",
        component: () =>
          import(
            /* webpackChunkName: "ProfileEdit" */ "./pages/Profile/ProfileBilling.vue"
          ),
        meta: {
          requiresAuth: true,
          title: "Billing - Xchange.Loans",
          hideFooter: true,
        },
        children: [
          {
            path: "cancel",
            component: () => import("./pages/Profile/ProfileBilling.vue"),
            beforeEnter: guardAdmin,
            meta: {
              requiresAuth: true,
              title: "Asset Approval - Xchange.Loans",
              hideFooter: true,
            },
          },
        ],
      },
    ],
  },
];
