<template>
  <v-form>
    <p
      class="cardPopUp__description"
      :class="isMobile && 'sign-to-continue'"
      v-if="!text"
    >
      Sign in to continue
    </p>
    <p class="cardPopUp__description" v-else>{{ text }}</p>
    <div class="second-input-with-background">
      <c-label> Email </c-label>
      <v-text-field
        class="formInput"
        placeholder="user@mail.com"
        v-model="email"
        :error-messages="emailErrors"
        @input="$v.email.$touch()"
        @blur="$v.email.$touch()"
        @keydown.enter="submit"
      ></v-text-field>
    </div>
    <div class="second-input-with-background">
      <c-label> Password </c-label>
      <v-text-field
        v-model="password"
        placeholder="Password"
        :type="passwordVisible ? 'text' : 'password'"
        @click:append="passwordVisible = !passwordVisible"
        :error-messages="passwordErrors"
        @input="$v.password.$touch()"
        @blur="$v.password.$touch()"
        @keydown.enter="submit"
      >
        <template v-slot:append>
          <v-icon
            class="custom-eye-icon"
            @click="passwordVisible = !passwordVisible"
            tabindex="-1"
            size="16"
            >{{ passwordVisible ? "mdi-eye" : "mdi-eye-off" }}
          </v-icon>
        </template>
      </v-text-field>
    </div>
    <v-btn color="#2969ff" text small @click="openForgotPasswordDialog">
      <div class="forgot-text">Forgot password?</div>
    </v-btn>
    <div
      v-if="confirmError"
      class="custom-error"
      style="background-color: #f1f1f1; text-align: center; font-size: 11px"
    >
      <p v-if="error">
        {{ error }}
      </p>
      <v-btn
        class="email-resend"
        color="#0F2D59"
        text
        small
        @click="openResendCodeDialog"
        >Resend verification email
      </v-btn>
    </div>
    <v-btn
      class="mb-6 login-btn mt-4"
      width="100%"
      color="primary"
      @click="submit"
      :loading="submitLoading"
      :disabled="!isAllValid"
    >
      <div class="login-text">Login</div>
    </v-btn>
    <div class="mt-4 mt-sm-6">
      <ZentinelLogo />
    </div>
  </v-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { Auth } from "aws-amplify";
import { required, email } from "vuelidate/lib/validators";
import handleLogsMixin from "@/mixins/handleLogsMixin";
import ZentinelLogo from "@/components/layout/ZentinelLogo";
import Clabel from "@/components/Common/CLabel.vue";
import Profile from "@/services/apiCalls/profile/profile";
import validateEmail from "@/utils/validations/email";
import { mapActions } from "vuex";
export default {
  name: "LoginForm",

  props: {
    userEmail: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    registerDialogProps: {
      type: Object,
      default: () => {},
    },
    forgotPasswordDialogProps: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    ZentinelLogo,
    "c-label": Clabel,
  },

  mixins: [validationMixin, handleLogsMixin],

  data() {
    return {
      inputColor: "#333333",
      checkboxTerms: false,
      passwordVisible: false,
      email: this.userEmail,
      password: "",
      submitLoading: false,
      error: "",
      confirmError: false,
    };
  },

  // watch: {
  //   email(newValue) {
  //     this.email = newValue.toLowerCase();
  //     if (newValue.length) {
  //       this.error = "";
  //     }
  //   },
  // },

  validations: {
    email: { required, email, validateEmail },
    password: { required },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Email is required");
      !this.$v.email.email && errors.push("Must be in correct email format");
      !this.$v.email.validateEmail && errors.push("Wrong symbols in email");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password is required");
      return errors;
    },
    validation() {
      return {
        uppercase: /[A-Z]/g.test(this.password),
        lowercase: /[a-z]/g.test(this.password),
        number: /\d/g.test(this.password),
        specialChar: /\W|_/g.test(this.password),
        isLength: this.password.length >= 8,
      };
    },
    isPasswordRequirmentsValid() {
      return (
        this.validation.uppercase &&
        this.validation.lowercase &&
        this.validation.number &&
        this.validation.specialChar &&
        this.validation.isLength
      );
    },
    isAllValid() {
      return this.email && validateEmail(this.email) && this.password;
    },
  },

  methods: {
    ...mapActions("AuthHelpers", ["signIn"]),
    showPasswordMessage() {
      this.$store.commit("openSnackBar", {
        text: "Password is incorrect or doesn’t comply with our policy. Check the minimum password length and password complexity.",
        color: "error",
      });
    },

    async submit() {
      if (!this.isPasswordRequirmentsValid) {
        this.showPasswordMessage();
        return;
      }

      this.error = "";
      this.confirmError = false;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.submitLoading = true;
        await this.signInFlow(this.email, this.password);
        const date = new Date();

        await Profile.updateLoginTime({ lastLogin: date.toISOString() });
        this.$emit("afterLoginBtnClick");
        this.$eventHub.$emit("successfulLogin");
        this.submitLoading = false;
        setTimeout(() => {
          try {
            this.$root.validateUserProfile();
          } catch (e) {
            console.log(e);
          }
        }, 300);
      }
    },
    async signInFlow(username, password) {
      try {
        const user = await this.signIn({ username, password });
        if (user && user.challengeName === "NEW_PASSWORD_REQUIRED") {
          Auth.completeNewPassword(
            user, // the Cognito User Object
            password, // the new password
            // OPTIONAL, the required attributes
            {
              email: username,
            }
          )
            .then(() => {
              this.$store.dispatch("logIn");
            })
            .catch((e) => {
              console.error(e);
              this.error = e;
            });
        }
        if (user) {
          // Vdr.createVDRClientUser({
          //     email: username,
          //     password: password
          // })
          //     .then(res => console.log(res))
          //     .catch(() => {
          //         this.error = 'Vdr while creating vdr client'
          //     })
          await this.$store.dispatch("logIn");
          if (localStorage.getItem("pathForVerify")) {
            const linkFromVerify = localStorage.getItem("pathForVerify");
            this.$router.push(linkFromVerify);
          }

          if (this.$gtm.enabled()) {
            window.dataLayer?.push({
              user_id: user.attributes.sub,
              event: "userIdSet",
            });
          }
        }
        this.submitLoading = false;
      } catch (err) {
        if (err.code === "UserNotConfirmedException") {
          this.confirmError = true;
          this.error =
            "User didn't finish the confirmation step when signing up";
        } else if (err.code === "PasswordResetRequiredException") {
          this.error = "Password Reset is required";
        } else if (err.code === "NotAuthorizedException") {
          this.error = "Incorrect password is provided";
        } else if (err.code === "UserNotFoundException") {
          this.error =
            "Either email or password you entered in incorrect. Please double-check and try again.";
        } else {
          this.error = err;
        }
        this.$store.commit("openSnackBar", {
          text: err.message,
          color: "error",
        });
        this.submitLoading = false;

        this.handleLogs({
          errorInfo: JSON.stringify(err),
          errorId: "Logging in User",
        });
      }
    },
    openForgotPasswordDialog() {
      this.$root.closeLoginDialog();
      this.$root.openForgotPasswordDialog(this.forgotPasswordDialogProps);
    },
    openRegisterDialog() {
      this.$root.closeLoginDialog();
      this.$root.openRegisterDialog(this.registerDialogProps);
    },
    openResendCodeDialog() {
      this.$root.closeLoginDialog();
      this.$root.openResendCodeDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-btn:not(.v-btn--round).v-size--small {
    padding: 0;
    margin-top: -14px;
    font-weight: 600;
  }
  .email-resend {
  }
  .custom-error {
    & p {
      line-height: unset !important;
      margin-bottom: 7px !important;
      width: 250px;
    }
  }
}
.linkForgotVisual {
  text-align: center;
  margin-bottom: 16px;
}
.text-check {
  margin-left: 12px;
  align-items: baseline;
  font-size: 9px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.1px;
  color: rgba(0, 17, 84, 0.7);
}
.v-btn__registration {
  padding: 0 12.5px !important;
}
.custom-eye-icon {
  padding-top: 2px;
  margin-right: 10px;
}
.custom__registration {
  margin-left: -8px;
}
.forgot-text {
  font-size: 12px;
}
.login-text {
  font-size: 16px;
}
@include mediaQ(
  map-get($mediabreakpoints, "xs"),
  map-get($mediabreakpoints, "sm")
) {
  .login-btn {
    height: 40px !important;
  }
  .forgot-text {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;

    text-align: center;

    color: #2969ff;
  }
  .login-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 26px;

    text-align: center;

    color: #ffffff;
  }
  .text-check {
    font-weight: 600;
    font-size: 12px;
  }
  .sign-up-text {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;

    text-align: center;

    color: #2969ff;
  }
  .sign-to-continue {
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 18px !important;

    color: #001154;
  }
  .second-input-with-background {
    .v-input {
      font-size: 14px !important;
    }
  }
}
</style>
